import React from "react";
import styled from "styled-components";
import { ReactComponent as Add } from "../assets/plus.svg";
import { StateDispatch } from "../App";
import { actions } from "../Reducer";

const Container = styled.button`
  width: 90%;
  margin: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  background-color: white;
  border-radius: 2px;
  border-color: lightgray;
  border-width: 1px;
  border-style: hidden;

  transition: background-color 0.5s ease;
  :hover {
    background-color: lightgray;
  }
`;

/**
 * React component that contains a styled button which dispatches an
 * ADD_FUNCTION action to the state based on the stackId it is given.
 *
 * @param {Object} props
 * @param {string} props.stackId
 */
export const AddFunctionButton = props => {
  const dispatch = React.useContext(StateDispatch);

  return (
    <Container
      onClick={() => {
        dispatch({
          type: actions.ADD_FUNCTION,
          payload: { name: "New Function", destinationId: props.stackId }
        });
      }}
    >
      <Add />
    </Container>
  );
};
