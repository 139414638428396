import React from "react";
import { Stack, AddStackButton } from "./index";
import styled from "styled-components";
import { Droppable } from "react-beautiful-dnd";

export const stackDroppable = "STACK";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 5px 0;
  &:not(:first-child) {
    border-top: 1px solid lightgray;
  }
`;

/**
 *  React component that contains a row of associated stacks. Is a Droppable
 *  associated with the Stack Droppable component.
 *
 *  @param {Object} props
 *  @param {string} props.id
 *  @param {Object} props.row
 *  @param {string} props.row.id
 *  @param {string[]} props.row.stackIds
 *  @param {Object[]} props.functions
 *  @param {Object[]} props.tools
 *  @param {Object[]} props.stacks
 */
export const Row = props => (
  <React.Fragment>
    <Droppable
      droppableId={props.id}
      type={stackDroppable}
      direction="horizontal"
    >
      {provided => (
        <Container ref={provided.innerRef} {...provided.droppableProps}>
          {props.row.stackIds.map((stackID, index) => {
            const stack = props.stacks[stackID];
            const functions = stack.functionIds.map(
              taskID => props.functions[taskID]
            );
            return (
              <Stack
                key={stack.id}
                stack={stack}
                functions={functions}
                tools={props.tools}
                location={{ sourceIndex: index, sourceId: props.id }}
              />
            );
          })}
          {provided.placeholder}
          <AddStackButton rowId={props.id} />
        </Container>
      )}
    </Droppable>
  </React.Fragment>
);
