import React from "react";
import styled from "styled-components";
import { Function, AddFunctionButton } from "./index";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { StateDispatch } from "../App";
import { Formik, Form, Field } from "formik";
import { actions as reducerActions } from "../Reducer";
import { ReactComponent as Edit } from "../assets/pencil.svg";

export const functionDroppable = "FUNCTION";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  min-width: 200px;
  margin: 8px;
  border: 1px solid lightgrey;
  border-radius: 2px;
  background-color: white;
`;
const Title = styled.h3`
  padding: 18px 8px;
  margin: 0px;
  display: flex;
`;
const FunctionList = styled.div`
  flex-grow: 1;
  padding: 8px;
  min-height: 100px;
`;
const EditButton = styled.button`
  display: none;
  justify-content: center;
  align-items: center;
  align-self: center;
  background-color: inherit;
  border-radius: 2px;
  border-color: lightgray;
  border-width: 1px;
  border-style: hidden;
  height: 24px;
  width: 24px;
  padding: 0;
  margin: 0;

  transition: background-color 0.5s ease;
  :hover {
    background-color: lightgray;
  }
  ${Title}:hover & {
    display: flex;
  }
`;

/**
 * React component to render a stack containing functions. Is a Draggable
 * associated with the row Droppable and is a Droppable associated with the
 * Function Draggable.
 *
 * @param {Object} props
 * @param {Object} props.location
 * @param {number} props.location.sourceIndex
 * @param {string} props.location.sourceId
 * @param {Object} props.stack
 * @param {string} props.stack.id
 * @param {string} props.stack.title
 * @param {string[]} props.stack.functionIds
 * @param {Object[]} props.functions
 * @param {Object[]} props.tools
 */
export const Stack = props => {
  const [edit, setEdit] = React.useState(false);
  const dispatch = React.useContext(StateDispatch);

  function toggleEditMode() {
    setEdit(!edit);
  }

  function deleteStack() {
    dispatch({
      type: reducerActions.DELETE_STACK,
      payload: {
        stackId: props.stack.id,
        sourceId: props.location.sourceId,
        sourceIndex: props.location.sourceIndex
      }
    });
  }

  return (
    <Draggable draggableId={props.stack.id} index={props.location.sourceIndex}>
      {provided => (
        <Container ref={provided.innerRef} {...provided.draggableProps}>
          {!edit ? (
            <Title {...provided.dragHandleProps}>
              {props.stack.title}
              <EditButton onClick={toggleEditMode}>
                <Edit />
              </EditButton>
            </Title>
          ) : (
            <Formik
              initialValues={props.stack}
              onSubmit={(values, actions) => {
                dispatch({
                  type: reducerActions.EDIT_STACK,
                  payload: values
                });
                toggleEditMode();
              }}
              render={props => (
                <Form>
                  <Field name="title" />
                  <button onClick={toggleEditMode}>Cancel</button>
                  <button onClick={deleteStack}>Delete</button>
                  <button type="submit">Save</button>
                </Form>
              )}
            />
          )}
          <Droppable droppableId={props.stack.id} type={functionDroppable}>
            {provided => (
              <FunctionList
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {props.functions.map((f, index) => (
                  <Function
                    key={f.id}
                    func={f}
                    tools={props.tools}
                    location={{ sourceId: props.stack.id, sourceIndex: index }}
                  />
                ))}
                {provided.placeholder}
              </FunctionList>
            )}
          </Droppable>
          <AddFunctionButton stackId={props.stack.id} />
        </Container>
      )}
    </Draggable>
  );
};
