import React from "react";
import styled from "styled-components";
import { Draggable } from "react-beautiful-dnd";
import { Tool, AddToolButton } from "./index";
import { ReactComponent as Edit } from "../assets/pencil.svg";
import { StateDispatch } from "../App";
import { Formik, Form, Field } from "formik";
import { actions as reducerActions } from "../Reducer";

const Container = styled.div`
  border: 1px solid lightgray;
  border-radius: 2px;
  padding: 8px;
  margin-bottom: 8px;
  background-color: white;
`;
const Title = styled.h4`
  margin: 8px 0;
  display: flex;
`;
const ToolList = styled.div`
  border: 1px solid lightgray;
  margin-left: 20px;
`;
const EditButton = styled.button`
  display: none;
  justify-content: center;
  align-items: center;
  align-self: center;
  background-color: inherit;
  border-radius: 2px;
  border-color: lightgray;
  border-width: 1px;
  border-style: hidden;
  height: 21px;
  width: 21px;
  padding: 0;
  margin: 0;

  transition: background-color 0.5s ease;
  :hover {
    background-color: lightgray;
  }
  ${Title}:hover & {
    display: flex;
  }
`;

/**
 * React component to display the contents of a function. Is a Draggable
 * associated with the stack Droppable
 *
 * @param {Object} props
 * @param {{id:string, name:string, toolIds:string[]}} props.func - A Function object
 * @param {Object} props.tools - The Tools state object
 * @param {Object} props.location
 * @param {string} props.location.sourceId
 * @param {number} props.location.sourceIndex
 */
export const Function = ({
  func,
  tools,
  location: { sourceId, sourceIndex }
}) => {
  const funcTools = func.toolIds.map(toolID => tools[toolID]);
  const [edit, setEdit] = React.useState(false);
  const dispatch = React.useContext(StateDispatch);

  function toggleEditMode() {
    setEdit(!edit);
  }

  function deleteFunction() {
    dispatch({
      type: reducerActions.DELETE_FUNCTION,
      payload: {
        functionId: func.id,
        sourceId,
        sourceIndex
      }
    });
  }

  return (
    <Draggable draggableId={func.id} index={sourceIndex}>
      {provided => {
        return (
          <Container
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            {!edit ? (
              <React.Fragment>
                <Title>
                  {func.name}
                  <EditButton onClick={toggleEditMode}>
                    <Edit />
                  </EditButton>
                </Title>
                <ToolList>
                  {funcTools.map((tool, index) => (
                    <Tool
                      key={index}
                      tool={tool}
                      location={{ sourceId: func.id, index }}
                    />
                  ))}
                  <AddToolButton functionId={func.id} />
                </ToolList>
              </React.Fragment>
            ) : (
              <Formik
                initialValues={func}
                onSubmit={(values, actions) => {
                  dispatch({
                    type: reducerActions.EDIT_FUNCTION,
                    payload: {
                      functionId: values.id,
                      name: values.name,
                      toolIds: values.toolIds
                    }
                  });
                  toggleEditMode();
                }}
                render={props => (
                  <Form>
                    <Field name="name" />
                    <button onClick={toggleEditMode}>Cancel</button>
                    <button onClick={deleteFunction}>Delete</button>
                    <button type="submit">Save</button>
                  </Form>
                )}
              />
            )}
          </Container>
        );
      }}
    </Draggable>
  );
};
