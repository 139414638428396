import React from "react";
import styled from "styled-components";
import { ReactComponent as Edit } from "../assets/pencil.svg";
import { ReactComponent as Proposed } from "../assets/help.svg";
import { ratingColour, Option, SvgContainer } from "./Styled";
import { Formik, Form, Field } from "formik";
import { StateDispatch } from "../App";
import { actions as reducerActions, ratings, types } from "../Reducer";
import { values } from "ramda";

const ViewContainer = styled.div`
  display: flex;
  padding: 0 8px;
  background: ${props => {
    // @ts-ignore
    return ratingColour(props.rating);
  }};
`;
const EditContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const Title = styled.p`
  margin: 8px;
  text-align: center;
  flex-grow: 1;
`;
const EditButton = styled.button`
  display: none;
  justify-content: center;
  align-items: center;
  align-self: center;
  background-color: inherit;
  border-radius: 2px;
  border-color: lightgray;
  border-width: 1px;
  border-style: hidden;
  height: 24px;
  width: 24px;
  padding: 0;
  margin: 0;

  transition: background-color 0.5s ease;
  :hover {
    background-color: lightgray;
  }
  ${ViewContainer}:hover & {
    display: flex;
  }
`;

/**
 * React component used to display a tool
 * @param {{tool:{id:string, name:string, type:string, rating:string}, location:{sourceId:string, index:number}}} props
 */
export const Tool = ({ tool, location: { sourceId, index } }) => {
  const [edit, setEdit] = React.useState(false);
  const dispatch = React.useContext(StateDispatch);

  function toggleEditMode() {
    setEdit(!edit);
  }

  function deleteTool() {
    dispatch({
      type: reducerActions.DELETE_TOOL,
      payload: {
        toolId: tool.id,
        sourceId,
        sourceIndex: index
      }
    });
  }

  return !edit ? (
    // @ts-ignore
    <ViewContainer rating={tool.rating}>
      <Title>{tool.name}</Title>
      {tool.type === types.PROPOSED ? (
        <SvgContainer>
          <Proposed />
        </SvgContainer>
      ) : null}
      <EditButton onClick={toggleEditMode}>
        <Edit />
      </EditButton>
    </ViewContainer>
  ) : (
    <EditContainer>
      <Formik
        initialValues={tool}
        onSubmit={(values, actions) => {
          dispatch({
            type: reducerActions.EDIT_TOOL,
            payload: {
              toolId: values.id,
              name: values.name,
              rating: values.rating,
              type: values.type
            }
          });
          toggleEditMode();
        }}
        render={props => (
          <Form>
            <Field name="name" />
            <Field component="select" name="rating">
              {values(ratings).map(value => (
                <Option key={value} value={value} />
              ))}
            </Field>
            <Field component="select" name="type">
              {values(types).map(value => (
                <Option key={value} value={value} />
              ))}
            </Field>
            <button type="button" onClick={toggleEditMode}>
              Cancel
            </button>
            <button type="button" onClick={deleteTool}>
              Delete
            </button>
            <button type="submit">Save</button>
          </Form>
        )}
      />
    </EditContainer>
  );
};
