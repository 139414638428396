import React from "react";
import { data } from "./initialData";
import { Row, functionDroppable, stackDroppable, Title } from "./components";
import { DragDropContext } from "react-beautiful-dnd";
import { reducer, actions, getInitialState } from "./Reducer";
import { saveAs } from "file-saver";

export const StateDispatch = React.createContext(null);

/**
 * Entry point for the application. Renders the rows based on the rowOrder array
 * in the state object. Manages the onDragEnd events for react-beautiful-dnd.
 * Provides context for the state dispatcher and react-beautiful-dnd.
 *
 * @see reducer for state management
 */
const App = () => {
  const [state, dispatch] = React.useReducer(reducer, data, getInitialState);
  const fileInput = React.useRef(null);

  function onDragEnd(result) {
    // No Destination
    if (!result.destination) {
      return;
    }

    // Function drag event
    if (result.type === functionDroppable) {
      dispatch({ type: actions.MOVE_FUNCTION, payload: result });
    }

    // Stack drag event
    if (result.type === stackDroppable) {
      dispatch({ type: actions.MOVE_STACK, payload: result });
    }
  }

  function saveStateToFile() {
    const file = new Blob([JSON.stringify(state, null, 4)], {
      type: "application/json;charset=utf-8"
    });
    saveAs(file);
  }

  async function loadStateFromFile() {
    const text = await new Response(fileInput.current.files[0]).text();
    dispatch({
      type: actions.RESET_STATE,
      payload: { newState: JSON.parse(text) }
    });
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <StateDispatch.Provider value={dispatch}>
        <Title title={state.title} />
        <button onClick={saveStateToFile}>Save</button>
        <input
          ref={fileInput}
          accept=".json"
          type="file"
          onChange={loadStateFromFile}
        />
        {data.rowOrder.map((rowId, index) => (
          <Row
            key={rowId}
            id={rowId}
            row={state.rows[rowId]}
            stacks={state.stacks}
            functions={state.functions}
            tools={state.tools}
          />
        ))}
      </StateDispatch.Provider>
    </DragDropContext>
  );
};

export default App;
