import React from "react";
import styled from "styled-components";
import { ReactComponent as Add } from "../assets/plus.svg";
import { StateDispatch } from "../App";
import { actions, ratings, types } from "../Reducer";

const Container = styled.button`
  width: 90%;
  margin: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  background-color: white;
  border-radius: 2px;
  border-color: lightgray;
  border-width: 1px;
  border-style: hidden;

  transition: background-color 0.5s ease;
  :hover {
    background-color: lightgray;
  }
`;

/**
 * React component that contains a styled button which dispatches an ADD_TOOL
 * action to the state based on the functionId it is given.
 *
 * @param {Object} props
 * @param {string} props.functionId
 */
export const AddToolButton = props => {
  const dispatch = React.useContext(StateDispatch);

  return (
    <Container
      onClick={() => {
        dispatch({
          type: actions.ADD_TOOL,
          payload: {
            name: "New Tool",
            destinationId: props.functionId,
            rating: ratings.CHAOTIC,
            type: types.ACTIVE
          }
        });
      }}
    >
      <Add />
    </Container>
  );
};
