import React from "react";
import { ratings } from "../Reducer";
import styled from "styled-components";

/**
 * Defines what colour should be displayed depending on a rating
 * @param {string} rating
 * @returns string
 */
export function ratingColour(rating) {
  switch (rating) {
    case ratings.CHAOTIC:
      return "crimson";
    case ratings.REACTIVE:
      return "red";
    case ratings.PREDICTABLE:
      return "darkorange";
    case ratings.SERVICE:
      return "forestgreen";
    case ratings.VALUE:
      return "dodgerblue";
    default:
      return "white";
  }
}

/**
 * @param {Object} props
 * @param {string} props.value
 */
export const Option = ({ value }) => <option value={value}>{value}</option>;

export const SvgContainer = styled.div`
  height: 24px;
  width: 24px;
  align-self: center;
`;
