import {
  append,
  compose,
  evolve,
  insert,
  lensProp,
  mergeDeepLeft,
  move,
  remove,
  set,
  over,
  dissoc,
  lensPath,
  assocPath,
  omit
} from "ramda";
import nanoid from "nanoid";

/**
 * Enum for possible reducer actions
 * @readonly
 * @enum {string}
 */
export const actions = {
  ADD_FUNCTION: "ADD_FUNCTION",
  ADD_STACK: "ADD_STACK",
  ADD_TOOL: "ADD_TOOL",
  DELETE_FUNCTION: "DELETE_FUNCTION",
  DELETE_STACK: "DELETE_STACK",
  DELETE_TOOL: "DELETE_TOOL",
  EDIT_FUNCTION: "EDIT_FUNCTION",
  EDIT_STACK: "EDIT_STACK",
  EDIT_TITLE: "EDIT_TITLE",
  EDIT_TOOL: "EDIT_TOOL",
  MOVE_FUNCTION: "MOVE_FUNCTION",
  MOVE_STACK: "MOVE_STACK",
  RESET_STATE: "RESET_STATE"
};

/**
 * Enum for tool rating options
 * @readonly
 * @enum {string}
 */
export const ratings = {
  CHAOTIC: "Chaotic",
  REACTIVE: "Reactive",
  PREDICTABLE: "Predictable",
  SERVICE: "Service",
  VALUE: "Value"
};

/**
 * Enum for tool type
 * @readonly
 * @enum {string}
 */
export const types = {
  ACTIVE: "Active",
  PROPOSED: "Proposed"
};

export const localStorageKey = "STATE";

/**
 * Used for loading initial state on page startup. Will restore state from a
 * previous session if the localStorage has data in the localStorageKey
 *
 * @param {Object} fallback - The fallback object that should be used as initial
 * state if nothing is found in localstorage
 */
export function getInitialState(fallback) {
  const state = JSON.parse(localStorage.getItem(localStorageKey));
  if (state) {
    return state;
  }

  return fallback;
}

/**
 * @param {any} state
 */
function saveToLocalStorage(state) {
  localStorage.setItem(localStorageKey, JSON.stringify(state));
}

/**
 * Handles all state modification actions.
 * @param {Object} state
 * @param {Object} action
 * @param {string} action.type - String describing the action
 * @param {Object} action.payload - Additional information needed to perform a
 * particular state transition. See the doc of the action function to see what
 * payload each needs.
 */
export function reducer(state, action) {
  const result = actionResult(state, action);
  saveToLocalStorage(result);
  return result;
}

/**
 * @param {any} state
 * @param {{ type: string; payload: any; }} action
 */
function actionResult(state, action) {
  switch (action.type) {
    case actions.MOVE_FUNCTION:
      return moveFunction(state, action.payload);
    case actions.MOVE_STACK:
      return moveStack(state, action.payload);
    case actions.ADD_STACK:
      return addStack(state, action.payload);
    case actions.ADD_FUNCTION:
      return addFunction(state, action.payload);
    case actions.ADD_TOOL:
      return addTool(state, action.payload);
    case actions.EDIT_TOOL:
      return editTool(state, action.payload);
    case actions.EDIT_FUNCTION:
      return editFunction(state, action.payload);
    case actions.DELETE_TOOL:
      return deleteTool(state, action.payload);
    case actions.DELETE_FUNCTION:
      return deleteFunction(state, action.payload);
    case actions.EDIT_STACK:
      return editStack(state, action.payload);
    case actions.DELETE_STACK:
      return deleteStack(state, action.payload);
    case actions.RESET_STATE:
      return resetState(state, action.payload);
    case actions.EDIT_TITLE:
      return editTitle(state, action.payload);
    default:
      throw new Error("No action given for reducer");
  }
}

/**
 * Used to reset the entire state to a new value no based of the previous state.
 * Useful for setting new state when loading from a file
 * @param {Object} state
 * @param {Object} payload
 * @param {Object} payload.newState - Defines the new state to set state to
 */
export function resetState(state, payload) {
  return payload.newState;
}

/**
 * Moves a function object to a new location based on the given
 * react-beautiful-dnd result
 * @param {Object} state - Current state object
 * @param {Object} payload - react-beautiful-dnd result object
 */
function moveFunction(state, payload) {
  const destinationId = payload.destination.droppableId;
  const sourceId = payload.source.droppableId;

  // Function being moved within the same stack
  if (destinationId === sourceId) {
    return evolve(
      {
        stacks: {
          [destinationId]: {
            functionIds: move(payload.source.index, payload.destination.index)
          }
        }
      },
      state
    );
  }

  // Function being moved to new stack
  if (destinationId !== sourceId) {
    return compose(
      evolve({
        stacks: {
          [destinationId]: {
            functionIds: insert(
              payload.destination.index,
              state.stacks[sourceId].functionIds[payload.source.index]
            )
          }
        }
      }),
      evolve({
        stacks: { [sourceId]: { functionIds: remove(payload.source.index, 1) } }
      })
    )(state);
  }
}

/**
 * Moves a stack object to a new location based on the given
 * react-beautiful-dnd result
 * @param {Object} state - Current state object
 * @param {Object} payload - react-beautiful-dnd result object
 */
function moveStack(state, payload) {
  const destinationId = payload.destination.droppableId;
  const sourceId = payload.source.droppableId;

  // Stack being moved within the same row
  if (destinationId === sourceId) {
    return evolve(
      {
        rows: {
          [destinationId]: {
            stackIds: move(payload.source.index, payload.destination.index)
          }
        }
      },
      state
    );
  }

  // Stack being moved to new row
  if (destinationId !== sourceId) {
    return compose(
      evolve({
        rows: {
          [sourceId]: { stackIds: remove(payload.source.index, 1) }
        }
      }),
      evolve({
        rows: {
          [destinationId]: {
            stackIds: insert(
              payload.destination.index,
              state.rows[sourceId].stackIds[payload.source.index]
            )
          }
        }
      })
    )(state);
  }
}

/**
 * Add a new stack to the given row
 * @param {Object} state - Current state object
 * @param {Object} payload
 * @param {string} payload.title - Title to be set for the new stack
 * @param {string} payload.destinationId - Defines which row the stack will be
 * appended to
 */
function addStack(state, payload) {
  const stackId = nanoid();

  return compose(
    mergeDeepLeft({
      stacks: {
        [stackId]: { id: stackId, title: payload.title, functionIds: [] }
      }
    }),
    evolve({ rows: { [payload.destinationId]: { stackIds: append(stackId) } } })
  )(state);
}

/**
 * Add a new function to the stack row
 * @param {Object} state - Current state object
 * @param {Object} payload
 * @param {string} payload.name - Name to be set for the new function
 * @param {string} payload.destinationId - Defines which stack the function will
 * be appended to
 */
function addFunction(state, payload) {
  const functionId = nanoid();

  return compose(
    mergeDeepLeft({
      functions: {
        [functionId]: { id: functionId, name: payload.name, toolIds: [] }
      }
    }),
    evolve({
      stacks: { [payload.destinationId]: { functionIds: append(functionId) } }
    })
  )(state);
}

/**
 * Add a new tool to a function
 * @param {Object} state - Current state object
 * @param {Object} payload
 * @param {string} payload.name - Name to be set for the new tool
 * @param {string} payload.rating - Defines tool rating should be an option from
 * the ratings enum
 * @param {string} payload.type - Defines tool type should be an option from the
 * types enum
 * @param {string} payload.destinationId - Defines which function the tool will
 * be appended to
 */
function addTool(state, payload) {
  const toolId = nanoid();

  return compose(
    assocPath(["tools", toolId], {
      id: toolId,
      name: payload.name,
      rating: payload.rating,
      type: payload.type
    }),
    over(
      lensPath(["functions", payload.destinationId, "toolIds"]),
      append(toolId)
    )
  )(state);
}

/**
 * Edit an existing tool (performs a total overwrite send all values)
 * @param {Object} state - Current state object
 * @param {Object} payload
 * @param {string} payload.name - Name to be set for the new tool
 * @param {string} payload.rating - Defines tool rating should be an option from
 * the ratings enum
 * @param {string} payload.type - Defines tool type should be an option from the
 * types enum
 * @param {string} payload.toolId - Which tool should be edited
 */
function editTool(state, payload) {
  return set(
    lensPath(["tools", payload.toolId]),
    {
      id: payload.toolId,
      name: payload.name,
      rating: payload.rating,
      type: payload.type
    },
    state
  );
}

/**
 * Removes a tool from the tools list and from the toolIds list of the function
 * it was stored in
 * @param {Object} state
 * @param {{toolId:string, sourceId:string, sourceIndex:number}} payload
 */
function deleteTool(state, payload) {
  return compose(
    over(lensProp("tools"), dissoc(payload.toolId)),
    over(
      lensPath(["functions", payload.sourceId, "toolIds"]),
      remove(payload.sourceIndex, 1)
    )
  )(state);
}

/**
 * Edit an existing function (performs a total overwrite so send all values)
 * @param {Object} state
 * @param {{name:string, functionId:string,toolIds:string[]}} payload
 */
function editFunction(state, payload) {
  return set(
    lensPath(["functions", payload.functionId]),
    { id: payload.functionId, name: payload.name, toolIds: payload.toolIds },
    state
  );
}

/**
 * Removes a function from the function list and from the functionIds list of the stack
 * it was stored in
 * @param {Object} state
 * @param {{functionId:string, sourceId:string, sourceIndex:number}} payload
 */
function deleteFunction(state, payload) {
  // remove tools => remove reference in stack => remove function
  return compose(
    over(lensProp("functions"), dissoc(payload.functionId)),
    over(
      lensPath(["stacks", payload.sourceId, "functionIds"]),
      remove(payload.sourceIndex, 1)
    ),
    over(lensProp("tools"), omit(state.functions[payload.functionId].toolIds))
  )(state);
}

/**
 * Edit an existing stack (performs a total overwrite so send all values)
 * @param {Object} state
 * @param {{title:string, id:string, functionIds:string[]}} payload
 */
function editStack(state, payload) {
  return set(
    lensPath(["stacks", payload.id]),
    {
      id: payload.id,
      title: payload.title,
      functionIds: payload.functionIds
    },
    state
  );
}

/**
 * @todo remove associated functions
 * @param {Object} state
 * @param {{stackId:string, sourceId:string, sourceIndex:number}} payload
 */
function deleteStack(state, payload) {
  return compose(
    over(lensProp("stacks"), dissoc(payload.stackId)),
    over(
      lensPath(["rows", payload.sourceId, "stackIds"]),
      remove(payload.sourceIndex, 1)
    )
  )(state);
}

/**
 * Change the text of app title
 * @param {Object} state
 * @param {{title:string}} payload
 */
function editTitle(state, payload) {
  return set(lensProp("title"), payload.title, state);
}
