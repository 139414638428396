import { ratings, types } from "./Reducer";

export const data = {
  title: "Testing Template",
  tools: {
    "tool-1": {
      id: "tool-1",
      name: "Tool 1",
      rating: ratings.CHAOTIC,
      type: types.ACTIVE
    },
    "tool-2": {
      id: "tool-2",
      name: "Tool 2",
      rating: ratings.REACTIVE,
      type: types.ACTIVE
    },
    "tool-3": {
      id: "tool-3",
      name: "Tool 3",
      rating: ratings.PREDICTABLE,
      type: types.ACTIVE
    },
    "tool-4": {
      id: "tool-4",
      name: "Tool 4",
      rating: ratings.SERVICE,
      type: types.ACTIVE
    },
    "tool-5": {
      id: "tool-5",
      name: "Tool 5",
      rating: ratings.VALUE,
      type: types.PROPOSED
    }
  },
  functions: {
    "function-1": { id: "function-1", name: "Function 1", toolIds: ["tool-1"] },
    "function-2": { id: "function-2", name: "Function 2", toolIds: ["tool-2"] },
    "function-3": { id: "function-3", name: "Function 3", toolIds: ["tool-3"] },
    "function-4": {
      id: "function-4",
      name: "Function 4",
      toolIds: ["tool-4", "tool-5"]
    }
  },
  stacks: {
    "stack-1": {
      id: "stack-1",
      title: "Stack 1",
      functionIds: ["function-1", "function-2", "function-3"]
    },
    "stack-2": {
      id: "stack-2",
      title: "Stack 2",
      functionIds: ["function-4"]
    },
    "stack-3": {
      id: "stack-3",
      title: "Stack 3",
      functionIds: []
    }
  },
  rows: {
    above: {
      id: "above",
      stackIds: ["stack-1", "stack-2"]
    },
    below: {
      id: "below",
      stackIds: ["stack-3"]
    }
  },
  rowOrder: ["above", "below"]
};
