import React from "react";
import { Formik, Form, Field } from "formik";
import styled from "styled-components";
import { ReactComponent as Edit } from "../assets/pencil.svg";
import { StateDispatch } from "../App";
import { actions as reducerActions } from "../Reducer";

const Heading = styled.h1`
  margin: 8px 0;
  display: flex;
`;
const EditButton = styled.button`
  display: none;
  justify-content: center;
  align-items: center;
  align-self: center;
  background-color: inherit;
  border-radius: 2px;
  border-color: lightgray;
  border-width: 1px;
  border-style: hidden;
  height: 21px;
  width: 21px;
  padding: 0;
  margin: 0;

  transition: background-color 0.5s ease;
  :hover {
    background-color: lightgray;
  }
  ${Heading}:hover & {
    display: flex;
  }
`;

export const Title = props => {
  const [edit, setEdit] = React.useState(false);
  const dispatch = React.useContext(StateDispatch);

  function toggleEditMode() {
    setEdit(!edit);
  }

  return !edit ? (
    <Heading>
      {props.title}
      <EditButton onClick={toggleEditMode}>
        <Edit />
      </EditButton>
    </Heading>
  ) : (
    <Formik
      initialValues={{ title: props.title }}
      onSubmit={(values, actions) => {
        dispatch({
          type: reducerActions.EDIT_TITLE,
          payload: {
            title: values.title
          }
        });
        toggleEditMode();
      }}
      render={props => (
        <Form>
          <Field name="title" />
          <button onClick={toggleEditMode}>Cancel</button>
          <button type="submit">Save</button>
        </Form>
      )}
    />
  );
};
